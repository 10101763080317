.VotingButton {
    flex-basis: 40%;
    margin-top: 24px;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 15px;
    margin-left: 12px;
}

.VotingContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}