.JoinRoomContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.InputRegister {
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  text-align: center; 
  border-radius: 10px;
  border: solid 0;
}

.InputRegister::placeholder {
    text-align: center; 
 }
 
 /* or, for legacy browsers */
 
 .InputRegister::-webkit-input-placeholder {
    text-align: center;
 }
 
 .InputRegister::-moz-placeholder { /* Firefox 18- */
    text-align: center;  
 }
 
 .InputRegister::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
 }
 
 .InputRegister::-ms-input-placeholder {  
    text-align: center; 
 }

.MarginTop {
  margin-top: 24px;
}
