.StepOneContainer {
    flex: 1;
    flex-direction: column
}

.StepTwoContainer {
    flex: 1;
    flex-direction: column
}

.LetterLabel {
    margin-top: 12px;
    margin-bottom: 12px;
}

.PictureContainer {
    background-color: white;
    margin: 24px;
    height: 45vh;
    border-radius: 15px;
}

.PictureContainer .dessin svg{
    border-radius: 15px 15px 0 0;
}

.PictureContainerStepThree img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.StepTwoContainer h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    font-size: large;
}

.outil{
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: aliceblue;
    border-radius: 0 0 15px 15px;

}

.outil .block{
    width: 50%;
    height: 5vh;
    
    float: left;
}
.outil .block.colourpicker{
    background-position:center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 0 0 0 15px;
    background-image: url("/public/images/picker_w.png");
}

.outil .block.colourpicker:active{
    background-color: white;
    background-image: url("/public/images/picker_b.png");
}

.outil .block.reset{
    border-radius: 0 0 15px 0;
    background-color: #FFDA40;
    font-size: xx-large;
    font-weight: 900;
    line-height: 42px;
}

.outil .block.reset:active{
    border-radius: 0 0 15px 0;
    background-color:black;
    font-size: xx-large;
    font-weight: 900;
    line-height: 42px;
    color:  #FFDA40;
}

.PickerContainer{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}


.buttonbottomStep2 {
    height: 10vh;
    width: 100%;
    display: block;
    bottom: 0;
    margin-top: auto;
    position: fixed;
}

.buttonContainerStep2 {
    width: 50%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.ButttonValidStep2 {
    margin-top: 2vh;
    width: 50%;
    height: auto;
    background-color: #FFDA40;
    border: none;
    color: black;
    padding: 15px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
}