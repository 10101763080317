.WaitingScreenContainer{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.WaitingImage {
    margin-top: 24px;
    margin-bottom: 24px;
    height: 200px;
    width: 200px;
    background-image: url("../images/oeilloading4x.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    animation-name: rotation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes rotation {
    0% {
        rotate: 0;
    }
    100%{
        rotate: 360deg;
    }   
}

.buttonContainer{
    width: 50%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
.playerselect{
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    background-size: contain;
}

.mouton{
    background-image: url("/public/images/mouton.png");
}
.chat{
    background-image: url("/public/images/chat.png");
}
.chevre{
    background-image: url("/public/images/chevre.png");
}
.cochon{
    background-image: url("/public/images/cochon.png");
}
.piaf{
    background-image: url("/public/images/piaf.png");
}
.singe{
    background-image: url("/public/images/singe.png");
}
.souris{
    background-image: url("/public/images/souris.png");
}
.trice{
    background-image: url("/public/images/trice.png");
}

.playerselect:active{
    animation-duration: 0.5s;
    animation-name: pouette;
    
}

@keyframes pouette {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}

.StartGame{
	margin-top: 2%;
	width: 50%;
	height: auto;
	background-color: white;
	border: none;
	color: black;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 5px;
	font-weight: 400;
	text-transform: uppercase;
}
.StartGame:active{
    background-color: #292E40;
    color: white;
}