.StepContainer {
  flex: 1;
  flex-direction: column;
  justify-items: center;
  display: flex;
  margin-top: 5%;
}

.LetterContainer {
    flex:1;
    justify-content: center;
    align-self: center;
    width: 80%;
    float: left;
    margin-bottom: 20px;
}
.LetterContainer h2{
  width: 20%;
  height: auto;
  line-height: 2.16;
  background-color:#FFDA40;
  color: black;
float:left ;
font-weight: 400;
text-transform: uppercase;
font-size: x-large;
border-radius: 10px 0 0 10px ;
border-right: 2px solid;
}

.Input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  text-align: center;
  width: 77%;
  border-radius:0 10px 10px 0;
  border: solid 0;
}

.Input::placeholder {
  text-align: center;
}

/* or, for legacy browsers */

.Input::-webkit-input-placeholder {
  text-align: center;
}

.Input::-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

.Input::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

.Input::-ms-input-placeholder {
  text-align: center;
}
