.StepThreeContainer {
  flex: 1;
  flex-direction: column;
}

.LetterLabel {
  margin-top: 12px;
  margin-bottom: 12px;
}

.PictureContainerStepThree {
  background-color: white;
  margin: 24px;
  height: 45vh;
  border-radius: 10px;
}

.Picture {
    border-radius: 10px;
}

.InputComponentThree {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  text-align: center;
  border-radius: 10px;
  border: solid 0;
  width: 100%;
}

.InputComponentThree::placeholder {
  text-align: center;
}

/* or, for legacy browsers */

.InputComponentThree::-webkit-input-placeholder {
  text-align: center;
}

.InputComponentThree::-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

.InputComponentThree::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

.InputComponentThree::-ms-input-placeholder {
  text-align: center;
}
