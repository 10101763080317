.SelectAvatarContainer {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 24px;
}

.AvatarContainer {
    display: inline-block;
    width: 25vw;
    height: auto;
    margin: 10px;
}

.AvatarSelected {
    background-color: red;
}

.PictureImage{
	object-fit:contain;
    object-position:center;
    animation-duration: 4s;
    animation-name: taille;
    animation-iteration-count: infinite;
    width: 100%;
}

@keyframes taille {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}
.margindemort{
    margin-left: -24px;
}

.buttonbottomAvatar {
    height: 10vh;
    width: 100%;
    display: block;
    bottom: 0;
    margin-top: auto;
    position: fixed;
}

.buttonContainerAvatar {
    width: 50%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.ButttonValidAvatar {
    margin-top: 2vh;
    width: 50%;
    height: auto;
    background-color: #FFDA40;
    border: none;
    color: black;
    padding: 15px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
}