/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/*-------------------------------------------------------------------------*/

body{
    background-image: url("./images/background.svg");
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #00DCD2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
    overflow-x: hidden;
}
.App{
	display: flex;
  	flex-direction: column;
  	flex: 1;
}

h1{
	font-family: 'Roboto', sans-serif;	
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
	font-size: large;
}

.logo{
	background-image: url("./images/golri.svg");
	background-repeat: no-repeat;
	height: 100px;
	width: 200px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2%;
	margin-top: 5%;
}
.join:active{
	background-color: red;
}

.buttonbottom{
	height: 20vh;
	width: 100%;
	display: block;
	bottom: 0;
	margin-top: auto;
}

.buttonbottom .ButttonValid{
	margin-top: 7vh;
	width: 50%;
	height: auto;
	background-color: #FFDA40;
	border: none;
	color: black;
	padding: 15px 0px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 5px;
}

.buttonbottom .ButttonValid:active{
	background-color: #BED9DE;
	color: white;
}

.buttonbottom .ButttonValid:disabled{
	background-color: #8a9ea1;
	color: white
}

footer{
	height: 20vh;
	width: 100%;
	background-color: white;
	display: block;
	bottom: 0;
	margin-top: auto;
	position: fixed;
}
footer .description{
	margin-top: 5%;
}
footer .description p, footer .description a{
	text-align: center;
	font-weight: 600;
	margin-top: 10px;
	display: block;
}
footer .description a{
	margin-left: auto;
	margin-right: auto;
}
